'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import { noop } from 'lodash'

import { VanPlacementConfigModel } from 'types/models/ads'

import { getAdPlacementId } from '../utils'
import useRequestedAdLogging from '../useRequestedAdLogging'
import { getVanCreative } from './utils'

type Props = {
  id: string
  config: VanPlacementConfigModel
  onAdRender?: (isAdVisible: boolean) => void
}

const VanAdvertisement = ({ id, config, onAdRender = noop }: Props) => {
  const [iframeContent, setIframeContent] = useState<string>()
  const isRequestedRef = useRef(false)
  const { onRequest: onRequestedAdRequest } = useRequestedAdLogging(getAdPlacementId(config))

  const fetchCreative = useCallback(async () => {
    isRequestedRef.current = true
    onRequestedAdRequest()

    const { response, text } = await getVanCreative(config.creative.contentLink)

    if (response.ok && response.status === 200 && text) {
      setIframeContent(text)
      onAdRender(true)
    } else {
      onAdRender(false)
    }
  }, [config, onRequestedAdRequest, onAdRender])

  useEffect(() => {
    if (iframeContent || isRequestedRef.current) return

    fetchCreative()
  }, [fetchCreative, iframeContent])

  if (!iframeContent) {
    return null
  }

  return (
    <div data-testid="van-advertisement" id={id}>
      <iframe
        title={`van-${config.id}-${config.creative.id}-iframe`}
        width={config.creative.size.width}
        height={config.creative.size.height}
        style={{ border: 'none' }}
        srcDoc={iframeContent}
      />
    </div>
  )
}

export default VanAdvertisement
